import React, { useState } from 'react';
import "../styles/menuBar.scss";
import { isPhone } from "../util/DeviceType";

export default (props) => {
    const { t } = props;

    const [showNewElement, setShowNewElement] = useState(false);
    const [opacity, setOpacity] = useState(0);
    const [isActive, setIsActive] = useState(false);


    // const handleClick = () => {
    //     if (opacity == 1) {
    //         setShowNewElement(false);
    //         document.body.style.overflow = 'visible'; // Show scrollbar
    //         setOpacity(0); // Set opacity to 1

    //         const menuBar = document.getElementById('menu-bar');
    //         const threeContainer = document.getElementById('home-three-id');
    //         const menuLetter01 = document.getElementById('menu-bar-link-desc-lett-1');
    //         const menuLetter02 = document.getElementById('menu-bar-link-desc-lett-2');

    //         if (menuBar) {
    //             menuBar.style.opacity = 0;
    //             menuBar.style.pointerEvents = 'none';
    //             threeContainer.style.paddingRight = '0px'

    //             menuLetter02.style.top = '10px'
    //             menuLetter02.style.opacity = '0'
    //             menuLetter02.addEventListener('transitionend', function () {
    //                 menuLetter02.style.display = 'none'

    //                 menuLetter01.style.top = '-10px'
    //                 menuLetter01.style.display = 'block'
    //                 setTimeout(() => {
    //                     menuLetter01.style.top = '0px'
    //                     menuLetter01.style.opacity = '1'
    //                 }, 20);
    //             }, { once: true })
    //         }
    //     } else {
    //         setShowNewElement(true);
    //         document.body.style.overflow = 'hidden'; // Hide scrollbar
    //         setOpacity(1); // Set opacity to 1

    //         const menuBar = document.getElementById('menu-bar');
    //         const threeContainer = document.getElementById('home-three-id');
    //         const menuLetter01 = document.getElementById('menu-bar-link-desc-lett-1');
    //         const menuLetter02 = document.getElementById('menu-bar-link-desc-lett-2');

    //         if (menuBar) {
    //             menuBar.style.opacity = 1;
    //             menuBar.style.pointerEvents = 'all';
    //             if (!isPhone()) threeContainer.style.paddingRight = '10px'


    //             menuLetter01.style.top = '10px'
    //             menuLetter01.style.opacity = '0'
    //             menuLetter01.addEventListener('transitionend', function () {
    //                 menuLetter01.style.display = 'none'

    //                 menuLetter02.style.top = '-10px'
    //                 menuLetter02.style.display = 'block'
    //                 setTimeout(() => {
    //                     menuLetter02.style.top = '0px'
    //                     menuLetter02.style.opacity = '1'
    //                 }, 20);
    //             }, { once: true })

    //         }
    //     }
    // };


    const handleClick = () => {
        setIsActive(!isActive); // Toggle active state

        if (opacity == 1) {
            setShowNewElement(false);
            document.body.style.overflow = 'visible'; // Show scrollbar
            setOpacity(0); // Set opacity to 1

            const menuBar = document.getElementById('menu-bar');
            const threeContainer = document.getElementById('home-three-id');

            if (menuBar) {
                menuBar.style.opacity = 0;
                menuBar.style.pointerEvents = 'none';
                threeContainer.style.paddingRight = '0px'
            }
        } else {
            setShowNewElement(true);
            document.body.style.overflow = 'hidden'; // Hide scrollbar
            setOpacity(1); // Set opacity to 1

            const menuBar = document.getElementById('menu-bar');
            const threeContainer = document.getElementById('home-three-id');

            if (menuBar) {
                menuBar.style.opacity = 1;
                menuBar.style.pointerEvents = 'all';
                if (!isPhone()) threeContainer.style.paddingRight = '10px'
            }
        }
    };

    const goToHome = () => {
        handleClick();
        const element = document.getElementById('section1');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }
    const goToAboutUs = () => {
        handleClick();
        const element = document.getElementById('section2');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }
    const goToProducts = () => {
        handleClick();
        const element = document.getElementById('section3');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }

    const goToTech = () => {
        handleClick();
        const element = document.getElementById('sectionN4');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }

    const goToService = () => {
        handleClick();
        const element = document.getElementById('sectionN5');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }

    const goToProcesses = () => {
        handleClick();
        const element = document.getElementById('sectionN6');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }

    const goToTeam = () => {
        handleClick();
        const element = document.getElementById('sectionN7');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }
    const goToContact = () => {
        handleClick();
        const element = document.getElementById('section5');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }

    return (
        <div>
            {/* <div className="menu-bar-link" onClick={handleClick}>
                <div className="menu-bar-link-open" >
                </div>
                <div className="menu-bar-link-desc">
                    <div id="menu-bar-link-desc-lett-1" className="menu-bar-link-desc-lett-1">{t('menuBarButtonTitle1')}</div>
                    <div id="menu-bar-link-desc-lett-2" className="menu-bar-link-desc-lett-2">{t('menuBarButtonTitle2')}</div>
                </div>
            </div> */}

            <div className="menu-bar-link" onClick={handleClick}>
                <div className="menu-bar-link-open" >
                    <div className="openbtn">
                        <span className={isActive ? 'activespan' : ''}></span>
                        <span className={isActive ? 'activespan' : ''}></span>
                        <span className={isActive ? 'activespan' : ''}></span>
                    </div>
                </div>
            </div>


            {/* {showNewElement && */}
            <div id="menu-bar" className={`menu-bar`} >
                <div className='menu-option'>
                    <div id='menu-option-1' className='menu-option-1'>
                        <div className='menu-num menu-lett'>01</div>
                        <div className='menu-title menu-lett' onClick={goToHome}>Home</div>
                    </div>
                    <div id='menu-option-2' className='menu-option-2'>
                        <div className='menu-num menu-lett'>02</div>
                        <a className='menu-title menu-lett' onClick={goToAboutUs}>About us</a>
                    </div>
                    <div id='menu-option-3' className='menu-option-3'>
                        <div className='menu-num menu-lett'>03</div>
                        <a className='menu-title menu-lett' onClick={goToProducts}>Products</a>
                    </div>

                    <div id='menu-option-4' className='menu-option-4'>
                        <div className='menu-num menu-lett'>04</div>
                        <a className='menu-title menu-lett' onClick={goToTech}>Technologies</a>
                    </div>

                    <div id='menu-option-4' className='menu-option-4'>
                        <div className='menu-num menu-lett'>05</div>
                        <a className='menu-title menu-lett' onClick={goToService}>Service types</a>
                    </div>

                    <div id='menu-option-4' className='menu-option-4'>
                        <div className='menu-num menu-lett'>06</div>
                        <a className='menu-title menu-lett' onClick={goToProcesses}>Processes</a>
                    </div>

                    <div id='menu-option-4' className='menu-option-4'>
                        <div className='menu-num menu-lett'>07</div>
                        <a className='menu-title menu-lett' onClick={goToTeam}>Team</a>
                    </div>

                    <div id='menu-option-4' className='menu-option-4'>
                        <div className='menu-num menu-lett'>08</div>
                        <a className='menu-title menu-lett' onClick={goToContact}>Contact</a>
                    </div>
                </div>
                <div className='menu-option menu-option-bottom'>
                    {/* <div className='menu-option-contact'>
                        <div className='menu-par-contact'>{t('contactBtn')}</div>
                        <div className='menu-num menu-lett-contact'>{t('email')}
                            <div className="menu-contact-line"></div></div>
                        <div className='menu-num menu-lett-contact'>{t('phoneNum')}
                            <div className="menu-contact-line"></div></div>
                        <div className="menu-bar-links">
                            <div className="menu-bar-social-block">
                                <a href="#" target="_blank">
                                    <img style={{ height: "20px" }} src={twitterPng} />
                                </a>
                                <a href="#" target="_blank">
                                    <img style={{ height: "20px" }} src={facebookPng} />
                                </a>
                                <a href="#" target="_blank">
                                    <img style={{ height: "20px" }} src={instagramPng} />
                                </a>
                                <a href="#" target="_blank">
                                    <img style={{ height: "20px" }} src={inPng} />
                                </a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* } */}
        </div>
    );
};
