
import { useEffect, useRef, useState, memo } from "react";
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { useTranslation, Trans } from 'react-i18next';
// import ExplosiveAttraction from './ExplosiveAttraction'
import ReactGa from 'react-ga'
import MenuBar from './MenuBar'
// import ModularMulti from './ModularMulti'
import { gsap, Power1 } from 'gsap';
// import Select, { StylesConfig } from 'react-select';
import { isLandscape, isPhone, isPortrait } from "../util/DeviceType";
import { createNoise3D } from 'simplex-noise';
import logoSrc from '../assets/Razor_logo_white.png'
import "../styles/section2.scss";
import "../styles/section3.scss";
import "../styles/section4.scss";
import "../styles/section5.scss";
import "../styles/languagePicker.scss";
//MACBOOK - 

import mainVideo from "./../assets//intro_1.mp4";

import instagramPng from "./../assets//images/Instagram.png";
import facebookPng from "./../assets//images/Facebook.png";
import inPng from "./../assets//images/in.png";
import twitterPng from "./../assets//images/Twitter.png";
import datsSrc1analitika from "./../assets//images/analitika.webp";
import datsSrc2animacija from "./../assets//images/animacija.webp";
import datsSrc3game from "./../assets//images/game.webp";
import datsSrc4dizajn from "./../assets//images/dizajn.webp";
import datsSrc5drustvenemreze from "./../assets//images/drustvenemreze.webp";
import datsSrc6kreiranjesadrzaja from "./../assets//images/kreiranjesadrzaja.webp";
import datsSrc7web from "./../assets//images/web.webp";
import datsSrc8softver from "./../assets//images/softver.webp";
import datsSrc9onlineprodaja from "./../assets//images/onlineprodaja.webp";
import datsSrc10mobile from "./../assets//images/mobile.webp";
import '../styles/explosiveAttraction.scss'


import game1 from "./../assets//images/godOfCoins.jpeg";
import game2 from "./../assets//images/sholin.jpeg";
import game3 from "./../assets//images/Heli.jpeg";

import devProcess1 from "./../assets//images/krug01.svg";
import devProcess2 from "./../assets//images/krug02.svg";

import person1 from "./../assets//images/ceo.svg";
import person2 from "./../assets//images/milos-jovanovic.svg";
import person3 from "./../assets//images/gligorije-lazarevic.svg";
import person4 from "./../assets//images/predrag-popovic.svg";


export default memo(({ antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, handleMaskFade, sliderStatusValue, textures, ...rest }) => {
    const reactCanvas = useRef(null);
    const [rendererCur, setRenderer] = useState(null);
    const [sceneCur, setScene] = useState(null);
    const [cameraCur, setCamera] = useState(null);
    const [showShowMore, setShowShowMore] = useState(false);
    const [showCapText, setShowCapText] = useState(0);
    const { t, i18n } = useTranslation();

    const recipient = t('email');
    const subject = t('subject');

    let renderer;
    let scene;
    let camera;
    let cameraCtrl;
    let userNavigationClicking = false;
    let userNavigationClickingEvent = false;
    let startY = 0;
    let startTimestamp = 0;

    const clock = new THREE.Clock();
    let pointLight, pointLight2;

    let width = window.innerWidth, height = window.innerHeight, cx = width / 2, cy = height / 2, poligonsReady;
    let light1, light2, light3, light4;

    let meshes;
    let mouseOver = false;

    let conf = {
        objectRadius: 2.5,
        objectDepth: 1,
        nx: Math.round(width / 20),
        ny: Math.round(height / 20),
        lookAtZ: 40
    };

    const lookAt = new THREE.Vector3(0, 0, conf.lookAtZ);
    const mouse = new THREE.Vector2();
    const mousePlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0).translate(new THREE.Vector3(0, 0, -conf.lookAtZ));
    const mousePosition = new THREE.Vector3();
    const raycaster = new THREE.Raycaster();

    const currentYear = new Date().getFullYear();
    const copyrightText = `Copyright © ${currentYear} Virsag. All Rights Reserved.`;


    useEffect(() => {
        const { current: canvas } = reactCanvas;

        sceneOptions = {
            useGeometryUniqueIdsMapSearch: true,
            useMaterialMeshMapSearch: true,
            useClonedMeshMap: true,
        };
        if (!canvas) return;


        // create a new WebGL renderer
        renderer = new THREE.WebGLRenderer({ canvas, antialias, ...engineOptions });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setClearColor(0x00ff00, 0);
        renderer.alpha = true;
        renderer.antialias = true;

        // create a new scene
        scene = new THREE.Scene();
        scene.background = new THREE.Color(0x000000);

        // create a new camera
        camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        // camera.position.set(0, 0, 60);
        camera.position.set(0, 0, 65);
        if (isPhone() && isLandscape() && (window.innerWidth < 1000)) camera.position.set(0, 0, 15);

        setRenderer(renderer.current);
        setScene(scene.current);
        setCamera(camera.current);

        // add objects to the scene
        creatingAssets();
        cameraConfig();

        function render() {
            if (renderer) renderer.render(scene, camera);
        }

        function animate() {
            if (renderer) {
                requestAnimationFrame(animate);
                render();
                const delta = clock.getDelta();


                // const element = document.getElementById('home-three-id');
                // // Move camera with scroling
                // if (camera)camera.position.y = (-element.scrollTop/9.2);

                const time = Date.now() * 0.001;
                const d = 100;
                light1.position.x = Math.sin(time * 0.1) * d;
                light1.position.y = Math.cos(time * 0.2) * d;
                light2.position.x = Math.cos(time * 0.3) * d;
                light2.position.y = Math.sin(time * 0.4) * d;
                light3.position.x = Math.sin(time * 0.5) * d;
                light3.position.y = Math.sin(time * 0.6) * d;
                light4.position.x = Math.sin(time * 0.7) * d;
                light4.position.y = Math.cos(time * 0.8) * d;

                if (meshes) {
                    const lookAt = mouseOver ? mousePosition : new THREE.Vector3(0, 0, 10000);
                    if (mouseOver) {
                        lookAt.x = mousePosition.x;
                        lookAt.y = mousePosition.y;
                        lookAt.z = conf.lookAtZ;
                    }

                    for (let i = 0; i < meshes.length; i++) {
                        if (!meshes[i].tween1.isActive() && !meshes[i].tween2.isActive() && poligonsReady) {
                            meshes[i].lookAt(lookAt);
                        }
                    }
                }

            }
        }

        animate();

        const resize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            if (isPhone() && isLandscape() && (window.innerWidth < 1000)) camera.position.set(0, 0, 15); else camera.position.set(0, 0, 65);
            renderer.setSize(width, height);
        };

        if (window) {
            window.addEventListener("resize", resize);
        }

        function creatingAssets() {
            createPoligonsWall()
        }

        function mousemoveEventPoligon(e) {
            const v = new THREE.Vector3();
            if (camera) camera.getWorldDirection(v);
            v.normalize();
            mousePlane.normal = v;

            mouseOver = true;
            mouse.x = e.clientX / width * 2 - 1;
            mouse.y = -(e.clientY / height) * 2 + 1;

            raycaster.setFromCamera(mouse, camera);
            raycaster.ray.intersectPlane(mousePlane, mousePosition);
        }

        function touchmoveEventPoligon(e) {
            userNavigationClicking = false;
            // e.preventDefault();
            const v = new THREE.Vector3();
            if (camera) camera.getWorldDirection(v);
            v.normalize();
            mousePlane.normal = v;

            mouseOver = true;
            const touch = e.touches[0]; // Get the first touch in the event
            mouse.x = touch.clientX / width * 2 - 1;
            mouse.y = -(touch.clientY / height) * 2 + 1;

            raycaster.setFromCamera(mouse, camera);
            raycaster.ray.intersectPlane(mousePlane, mousePosition);

            const currentY = touch.clientY;
            const deltaY = currentY - startY;

            const currentTimestamp = Date.now();
            const elapsedMilliseconds = currentTimestamp - startTimestamp;
            const velocity = Math.abs(deltaY / elapsedMilliseconds);
            if (velocity > 0.5) {
                // Finger movement is fast, allow scrolling
            } else {
                // Finger movement is slow, prevent scrolling
                e.preventDefault();
            }

        }


        function mouseoutEventPoligon() {
            mouseOver = false;
            if (userNavigationClicking) {
                touchstartEventPoligon();
                userNavigationClicking = false;
            }
        }

        function clickEventPoligon(event) {
            event.preventDefault();
            if (poligonsReady) {
                let mouse = new THREE.Vector2();
                mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
                mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

                let raycaster = new THREE.Raycaster();
                raycaster.setFromCamera(mouse, camera);

                let intersects = raycaster.intersectObjects(meshes);

                if (intersects.length > 0) {

                    let completed = false;
                    for (let i = 0; i < meshes.length; i++) {
                        if (!meshes[i].tween2.isActive() && !meshes[i].tween2.isActive() && poligonsReady) {
                            completed = true;
                        }
                    }

                    if (completed) {
                        meshes.forEach(element => {
                            let oldPositionX = element.oldPosition.x;
                            let oldPositionY = element.oldPosition.y;
                            let oldPositionZ = element.oldPosition.z;
                            let duration = 1 + rnd(2);
                            let oldRotationX = element.oldRotation.x;
                            let oldRotationY = element.oldRotation.y;
                            let oldRotationZ = element.oldRotation.z;
                            poligonsReady = false;

                            element.tween1 = gsap.to(element.position, {
                                x: oldPositionX * rnd(1.2, true), y: oldPositionY * rnd(1.2, true), z: oldPositionZ, ease: Power1.easeOut, duration: duration / 4, onComplete: function () {
                                    element.tween1 = gsap.to(element.position, { x: oldPositionX, y: oldPositionY, z: 0, ease: Power1.easeOut, duration: duration });
                                }
                            });

                            // Different animation
                            // element.tween1 = gsap.to(element.position, {
                            //     x: oldPositionX, y: oldPositionY, z: oldPositionZ, ease: Power1.easeOut, duration: duration / 4, onComplete: function () {
                            //         element.tween1 = gsap.to(element.position, { z: 0, ease: Power1.easeOut, duration: duration });
                            //         poligonsReady = true;
                            //     }
                            // });

                            element.tween2 = gsap.to(element.rotation, {
                                x: oldRotationX, y: oldRotationY, z: oldRotationZ, ease: Power1.easeOut, duration: duration / 4, onComplete: function () {
                                    element.tween2 = gsap.to(element.rotation, { x: 0, y: 0, z: 0, ease: Power1.easeOut, duration: duration + 1.5 });
                                    poligonsReady = true;
                                }
                            });
                        });
                    }
                }
            }
        }
        function touchstartEventPoligon(event = userNavigationClickingEvent) {
            // event.preventDefault();
            userNavigationClickingEvent = event;
            const touch = event.touches[0];
            startY = touch.clientY;
            startTimestamp = Date.now();

            if (poligonsReady && userNavigationClicking) {
                let mouse = new THREE.Vector2();
                const touch = event.touches[0]; // Get the first touch in the event
                mouse.x = (touch.clientX / window.innerWidth) * 2 - 1;
                mouse.y = -(touch.clientY / window.innerHeight) * 2 + 1;

                let raycaster = new THREE.Raycaster();
                raycaster.setFromCamera(mouse, camera);

                let intersects = raycaster.intersectObjects(meshes);

                if (intersects.length > 0) {
                    let completed = false;
                    for (let i = 0; i < meshes.length; i++) {
                        if (!meshes[i].tween2.isActive() && !meshes[i].tween2.isActive() && poligonsReady) {
                            completed = true;
                        }
                    }

                    if (completed) {
                        meshes.forEach(element => {
                            let oldPositionX = element.oldPosition.x;
                            let oldPositionY = element.oldPosition.y;
                            let oldPositionZ = element.oldPosition.z;
                            let duration = 1 + rnd(2);
                            let oldRotationX = element.oldRotation.x;
                            let oldRotationY = element.oldRotation.y;
                            let oldRotationZ = element.oldRotation.z;
                            poligonsReady = false;

                            element.tween1 = gsap.to(element.position, {
                                x: oldPositionX * rnd(1.2, true), y: oldPositionY * rnd(1.2, true), z: oldPositionZ, ease: Power1.easeOut, duration: duration / 4, onComplete: function () {
                                    element.tween1 = gsap.to(element.position, { x: oldPositionX, y: oldPositionY, z: 0, ease: Power1.easeOut, duration: duration });
                                }
                            });

                            element.tween2 = gsap.to(element.rotation, {
                                x: oldRotationX, y: oldRotationY, z: oldRotationZ, ease: Power1.easeOut, duration: duration / 4, onComplete: function () {
                                    element.tween2 = gsap.to(element.rotation, { x: 0, y: 0, z: 0, ease: Power1.easeOut, duration: duration + 1.5 });
                                    poligonsReady = true;
                                }
                            });
                        });
                    }
                }
            }
            userNavigationClicking = true;
        }


        // function clickEventPoligon() {
        //     if (meshes) {
        //         let completed = false;
        //         for (let i = 0; i < meshes.length; i++) {
        //             if (!meshes[i].tween2.isActive() && !meshes[i].tween2.isActive() && poligonsReady) {
        //                 completed = true;
        //             }
        //         }

        //         if (completed) {
        //             meshes.forEach(element => {
        //                 let oldPositionX = element.oldPosition.x;
        //                 let oldPositionY = element.oldPosition.y;
        //                 let oldPositionZ = element.oldPosition.z;
        //                 let duration = 1 + rnd(2);
        //                 let oldRotationX = element.oldRotation.x;
        //                 let oldRotationY = element.oldRotation.y;
        //                 let oldRotationZ = element.oldRotation.z;
        //                 poligonsReady = false;

        //                 element.tween1 = gsap.to(element.position, {
        //                     x: oldPositionX*rnd(1.2,true), y: oldPositionY*rnd(1.2,true), z: oldPositionZ, ease: Power1.easeOut, duration: duration / 4, onComplete: function () {
        //                         element.tween1 = gsap.to(element.position, { x: oldPositionX, y: oldPositionY, z: 0, ease: Power1.easeOut, duration: duration });
        //                         poligonsReady = true;
        //                     }
        //                 });

        //                 // Different animation
        //                 // element.tween1 = gsap.to(element.position, {
        //                 //     x: oldPositionX, y: oldPositionY, z: oldPositionZ, ease: Power1.easeOut, duration: duration / 4, onComplete: function () {
        //                 //         element.tween1 = gsap.to(element.position, { z: 0, ease: Power1.easeOut, duration: duration });
        //                 //         poligonsReady = true;
        //                 //     }
        //                 // });

        //                 element.tween2 = gsap.to(element.rotation, {
        //                     x: oldRotationX, y: oldRotationY, z: oldRotationZ, ease: Power1.easeOut, duration: duration / 4, onComplete: function () {
        //                         element.tween2 = gsap.to(element.rotation, { x:0,y:0,z: 0, ease: Power1.easeOut, duration: duration +1.5 });
        //                         poligonsReady = true;
        //                     }
        //                 });
        //             });
        //         }
        //     }
        // }

        function createPoligonsWall() {


            function init() {
                initScene();

                const canvasS = document.getElementById("section1");
                const section2After = document.getElementById("section2-after");
                const section3After = document.getElementById("section3-after");
                const section4After = document.getElementById("section4-after");


                if (isPhone()) {
                    canvasS.addEventListener('touchstart', touchstartEventPoligon);
                    canvasS.addEventListener('touchend', mouseoutEventPoligon);
                    canvasS.addEventListener('touchmove', touchmoveEventPoligon);

                    section2After.addEventListener('touchstart', touchstartEventPoligon);
                    section2After.addEventListener('touchend', mouseoutEventPoligon);
                    section2After.addEventListener('touchmove', touchmoveEventPoligon);

                    section3After.addEventListener('touchstart', touchstartEventPoligon);
                    section3After.addEventListener('touchend', mouseoutEventPoligon);
                    section3After.addEventListener('touchmove', touchmoveEventPoligon);

                    if (section4After) section4After.addEventListener('touchstart', touchstartEventPoligon);
                    if (section4After) section4After.addEventListener('touchend', mouseoutEventPoligon);
                    if (section4After) section4After.addEventListener('touchmove', touchmoveEventPoligon);

                } else {
                    canvasS.addEventListener('click', clickEventPoligon);
                    canvasS.addEventListener('mouseout', mouseoutEventPoligon);
                    canvasS.addEventListener('mousemove', mousemoveEventPoligon);

                    section2After.addEventListener('click', clickEventPoligon);
                    section2After.addEventListener('mouseout', mouseoutEventPoligon);
                    section2After.addEventListener('mousemove', mousemoveEventPoligon);

                    section3After.addEventListener('click', clickEventPoligon);
                    section3After.addEventListener('mouseout', mouseoutEventPoligon);
                    section3After.addEventListener('mousemove', mousemoveEventPoligon);

                    if (section4After) section4After.addEventListener('click', clickEventPoligon);
                    if (section4After) section4After.addEventListener('mouseout', mouseoutEventPoligon);
                    if (section4After) section4After.addEventListener('mousemove', mousemoveEventPoligon);
                }

                // animate(); 
            };

            function initScene() {

                initLights();

                meshes = [];
                let mat = new THREE.MeshStandardMaterial({ color: 0x5c5c5c, roughness: 0.4, metalness: 0.9 });
                let geo = polygonGeometry(6, 0, 0, conf.objectRadius, 0);
                let mesh;
                const dx = Math.cos(Math.PI / 6) * conf.objectRadius * 2;
                const dy = conf.objectRadius * 1.5;
                for (let j = 0; j < conf.ny; j++) {
                    for (let i = 0; i < conf.nx; i++) {
                        mesh = new THREE.Mesh(geo, mat);
                        mesh.position.x = (-conf.nx / 2 + i) * dx + j % 2 / 2 * dx;
                        mesh.position.y = (-conf.ny / 2 + j) * dy;
                        mesh.position.z = -200 - rnd(50);
                        mesh.rotation.x = rnd(2 * Math.PI, true);
                        mesh.rotation.y = rnd(2 * Math.PI, true);
                        mesh.rotation.z = rnd(2 * Math.PI, true);
                        let duration = 1 + rnd(2);
                        mesh.oldPosition = new THREE.Vector3(mesh.position.x, mesh.position.y, mesh.position.z);
                        mesh.oldRotation = new THREE.Vector3(mesh.rotation.x, mesh.rotation.y, mesh.rotation.z);

                        mesh.tween1 = gsap.to(mesh.position, { z: 0, ease: Power1.easeOut, duration: duration });
                        // mesh.tween2 = gsap.to(mesh.rotation, { x: 10, ease: Power1.easeOut, duration:duration });
                        mesh.tween2 = gsap.to(mesh.rotation, {
                            x: 0, y: 0, z: 0, ease: Power1.easeOut, duration: duration + 1.5, onComplete: function (o) {
                                poligonsReady = true;
                            }
                        });

                        meshes.push(mesh);
                        scene.add(mesh);
                    }
                }
            }

            function initLights() {
                const r = 60;
                const lightIntensity = 0.2;
                const lightDistance = 300;

                scene.add(new THREE.AmbientLight(0xffffff));

                light1 = new THREE.PointLight(randomColor(1), lightIntensity, lightDistance);
                light1.position.set(0, r, r);
                scene.add(light1);
                light2 = new THREE.PointLight(randomColor(2), lightIntensity, lightDistance);
                light2.position.set(0, -r, r);
                scene.add(light2);
                light3 = new THREE.PointLight(randomColor(3), lightIntensity, lightDistance);
                light3.position.set(r, 0, r);
                scene.add(light3);
                light4 = new THREE.PointLight(randomColor(4), lightIntensity, lightDistance);
                light4.position.set(-r, 0, r);
                scene.add(light4);
            }

            function randomColor(id = 0) {
                let noise3D = createNoise3D(Math.random);

                let x = 360 * 0.08;
                let y = 360 * 0.08;
                let z = 360 * 0.08;

                var l = Math.round((Math.abs(noise3D(x, y, z))) * 60);
                if (id != 0) {
                    switch (id) {
                        case 1:
                            return "hsla(190, 100%, 50%, 1)";
                            break;
                        case 2:
                            return "hsla(172, 100%, 50%, 1)";
                            break;
                        case 3:
                            return "hsla(163, 100%, 50%, 1)";
                            break;
                        case 4:
                            return "hsla(192, 100%, 50%, 1)";
                            break;
                    }
                }
                return "hsl(" + 0 + ",100%," + l + "%)";
            }

            // function animate() {
            //     requestAnimationFrame(animate);


            //     const time = Date.now() * 0.001;
            //     const d = 100;
            //     light1.position.x = Math.sin(time * 0.1) * d;
            //     light1.position.y = Math.cos(time * 0.2) * d;
            //     light2.position.x = Math.cos(time * 0.3) * d;
            //     light2.position.y = Math.sin(time * 0.4) * d;
            //     light3.position.x = Math.sin(time * 0.5) * d;
            //     light3.position.y = Math.sin(time * 0.6) * d;
            //     light4.position.x = Math.sin(time * 0.7) * d;
            //     light4.position.y = Math.cos(time * 0.8) * d;

            //     const lookAt = mouseOver ? mousePosition : new THREE.Vector3(0, 0, 10000);
            //     if (mouseOver) {
            //         lookAt.x = mousePosition.x;
            //         lookAt.y = mousePosition.y;
            //         lookAt.z = conf.lookAtZ;
            //     }

            //     // for (let i = 0; i < meshes.length; i++) {
            //     //     if (!meshes[i].tween1.isActive() && !meshes[i].tween2.isActive())
            //     //         meshes[i].lookAt(lookAt);
            //     // }

            //     renderer.render(scene, camera);
            // };

            function polygonGeometry(n, x, y, s, r) {
                let points = ppoints(n, x, y, s, r);
                let shape = new THREE.Shape();
                points.forEach((p, i) => {
                    if (i === 0) shape.moveTo(p[0], p[1]); else
                        shape.lineTo(p[0], p[1]);
                });
                shape.lineTo(points[0][0], points[0][1]);

                let extrudeSettings = { steps: 1, depth: conf.objectDepth, bevelEnabled: false };
                let geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
                geometry.translate(0, 0, -conf.objectDepth / 2);
                return geometry;
            }

            function ppoints(n, x, y, s, r) {
                const dt = 2 * Math.PI / n;
                let points = [], t, px, py;
                for (let i = 0; i < n; i++) {
                    t = Math.PI / 2 + r + i * dt;
                    px = x + Math.cos(t) * s;
                    py = y + Math.sin(t) * s;
                    points.push([px, py]);
                }
                return points;
            }

            function onWindowResize() {
                width = window.innerWidth; cx = width / 2;
                height = window.innerHeight; cy = height / 2;
                camera.aspect = width / height;
                camera.updateProjectionMatrix();
                renderer.setSize(width, height);
            }


            init();
        }

        function rnd(max, negative) {
            return negative ? Math.random() * 2 * max - max : Math.random() * max;
        }

        function cameraConfig() {
            cameraCtrl = new OrbitControls(camera, renderer.domElement);
            cameraCtrl.enableDamping = false;
            cameraCtrl.dampingFactor = 0.1;
            cameraCtrl.autoRotate = false;
            cameraCtrl.autoRotateSpeed = 0.5;
            cameraCtrl.rotateSpeed = 0.5;
            cameraCtrl.enableZoom = false;
            cameraCtrl.enableRotate = true;
            cameraCtrl.enablePan = true;
            cameraCtrl.enabled = false;
            // cameraCtrl.minDistance = 10;
            // cameraCtrl.maxDistance = 100;

            //if (cameraCtrl) cameraCtrl.update();
            renderer.render(scene, camera);

            // pointLight = new THREE.PointLight(0xffffff);
            // pointLight.position.set(0, 0, 1000);
            // scene.add(pointLight);

            pointLight2 = new THREE.PointLight(0xffffff);
            pointLight2.position.set(0, 0, -1000);
            // scene.add(pointLight2);
            scene.add(new THREE.AmbientLight(0xffffff));
        }

        return () => {
            const canvasS = document.getElementById("section1");
            const section2After = document.getElementById("section2-after");
            const section3After = document.getElementById("section3-after");
            const section4After = document.getElementById("section4-after");

            if (isPhone()) {
                canvasS.removeEventListener('touchstart', touchstartEventPoligon);
                canvasS.removeEventListener('touchend', mouseoutEventPoligon);
                canvasS.removeEventListener('touchmove', touchmoveEventPoligon);

                section2After.removeEventListener('touchstart', touchstartEventPoligon);
                section2After.removeEventListener('touchend', mouseoutEventPoligon);
                section2After.removeEventListener('touchmove', touchmoveEventPoligon);

                section3After.removeEventListener('touchstart', touchstartEventPoligon);
                section3After.removeEventListener('touchend', mouseoutEventPoligon);
                section3After.removeEventListener('touchmove', touchmoveEventPoligon);

                if (section4After) section4After.removeEventListener('touchstart', touchstartEventPoligon);
                if (section4After) section4After.removeEventListener('touchend', mouseoutEventPoligon);
                if (section4After) section4After.removeEventListener('touchmove', touchmoveEventPoligon);

            } else {
                canvasS.removeEventListener('click', clickEventPoligon);
                canvasS.removeEventListener('mouseout', mouseoutEventPoligon);
                canvasS.removeEventListener('mousemove', mousemoveEventPoligon);

                section2After.removeEventListener('click', clickEventPoligon);
                section2After.removeEventListener('mouseout', mouseoutEventPoligon);
                section2After.removeEventListener('mousemove', mousemoveEventPoligon);

                section3After.removeEventListener('click', clickEventPoligon);
                section3After.removeEventListener('mouseout', mouseoutEventPoligon);
                section3After.removeEventListener('mousemove', mousemoveEventPoligon);

                if (section4After) section4After.removeEventListener('click', clickEventPoligon);
                if (section4After) section4After.removeEventListener('mouseout', mouseoutEventPoligon);
                if (section4After) section4After.removeEventListener('mousemove', mousemoveEventPoligon);
            }
            canvasS = null;
            section2After = null;
            section3After = null;
            if (section4After) section4After = null;

            if (scene.current && camera.current) {
                scene.current.dispose();
                camera.current.dispose();
            }
            if (renderer.current) {
                renderer.current.dispose();
            }

            if (window) {
                window.removeEventListener("resize", resize);
            }

            if (rendererCur) rendererCur.dispose();
            if (sceneCur) sceneCur.dispose();
            if (cameraCur) cameraCur.dispose();

            setRenderer(null);
            setScene(null);
            setCamera(null);
            pointLight = null;
            renderer = null; scene = null; camera = null; pointLight = null; pointLight2 = null;
        };
    }, []);


    const handleLanguageChangeSr = () => {
        i18n.changeLanguage('rs');

        const elementSection2Desc = document.getElementById('section2-text-description');
        const newTransComponent = document.getElementById('section2TextDesc');
        while (elementSection2Desc.firstChild) {
            elementSection2Desc.removeChild(elementSection2Desc.firstChild);
        }
        elementSection2Desc.appendChild(newTransComponent);
    };
    const handleLanguageChangeEn = () => {
        i18n.changeLanguage('en');

        const elementSection2Desc = document.getElementById('section2-text-description');
        const newTransComponent = document.getElementById('section2TextDesc');
        while (elementSection2Desc.firstChild) {
            elementSection2Desc.removeChild(elementSection2Desc.firstChild);
        }
        elementSection2Desc.appendChild(newTransComponent);
    };

    const hideCapDescElement = () => {
        setShowShowMore(false)
    };

    const showCapDescElement = (id = 0) => {
        setShowShowMore(true);
        setShowCapText(id);
    };

    const goToOurServices = () => {
        const element = document.getElementById('section4');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }
    useEffect(() => {
        const capBlock = document.getElementById('capBlock');
        if (showShowMore && capBlock) {
            capBlock.style.opacity = '1'
            capBlock.style.pointerEvents = 'all'
        } else if (!showShowMore && capBlock) {
            capBlock.style.opacity = '0'
            capBlock.style.pointerEvents = 'none'
        }

    }, [showShowMore]);

    useEffect(() => {
        if (showCapText) {
            const element = document.getElementById('cap-description-text-title');
            const element2 = document.getElementById('cap-description-text-description');
            if (element && element2) {
                const translations = {
                    1: { title: t('card1Title'), description: t('card1Desc') },
                    2: { title: t('card2Title'), description: t('card2Desc') },
                    3: { title: t('card3Title'), description: t('card3Desc') },
                    4: { title: t('card4Title'), description: t('card4Desc') },
                    5: { title: t('card5Title'), description: t('card5Desc') },
                    6: { title: t('card6Title'), description: t('card6Desc') },
                    7: { title: t('card7Title'), description: t('card7Desc') },
                    8: { title: t('card8Title'), description: t('card8Desc') },
                    9: { title: t('card9Title'), description: t('card9Desc') },
                    10: { title: t('card10Title'), description: t('card10Desc') },
                };

                const { title, description } = translations[showCapText] || {};

                element.textContent = title || '';
                element2.textContent = description || '';
            }

            setShowCapText(null);
        };
    }, [showCapText]);


    const lngOptions = [
        { value: 'rs', label: 'Srpski' },
        { value: 'en', label: 'English' },
    ]
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: 'none',
            boxShadow: state.isFocused ? '0 0 0 2px #00FFB7' : 'none',
            //   backgroundColor: state.isFocused ? '#00FFB7' : '#fff',
            fontWeight: "bold"
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#00FFB7' : 'white',
            color: state.isFocused ? 'black' : 'black',
            fontWeight: "bold"
        }),
    };

    return (
        <div id="home-three-id" className="home-three">
            {/* <ExplosiveAttraction /> */}

            <div className="home-header-logo">
                <img src={logoSrc} alt="Logo" />
                <MenuBar t={t} />
            </div>

            <div id="threejs-canvas" className="home-header-logo-parent">
                <canvas ref={reactCanvas} {...rest} >
                </canvas>
            </div>

            {/* Header 1 */}
            <div id="section1" className="section">
                <video
                    src={mainVideo}
                    autoPlay // Enables autoplay
                    loop     // Replays the video once it ends
                    muted    // Mutes the video to allow autoplay in browsers
                    playsInline // Ensures the video works on mobile devices inline
                >
                    Your browser does not support the video tag.
                </video>

            </div>

            {/* About Us 2 */}
            <div id="section2" className="section">
                <div id="section2-text-description" className="section2-text-description">

                    {!isPortrait() &&
                        <>
                            <p>We are an iGaming development outsourcing company, working with clients</p>
                            <p>worldwide. Whether you’re looking for the custom slot, table, or scratch</p>
                            <p>online game software, or any other Casino game that uses innovative</p>
                            <p>technology, we have experts ready to help.</p>
                        </>
                    }
                    {isPortrait() &&
                        <>
                            <p>We are an iGaming development outsourcing company, working with clients worldwide. Whether you’re looking for the custom slot, table, or scratch online game software, or any other Casino game that uses innovative technology, we have experts ready to help.</p>
                        </>
                    }
                </div>
            </div>
            <div id="section2-after" className="section-after" />

            {/* Products 3 */}
            <div id="section3" className="section">
                <h2 className="section3-text-title1 section3-text-titles">Products</h2>
                <p className="teamname">The team can convert your vision into some of the most popular Casino games in the world</p>
                <p className="teamname">Type of games that we can develop:</p>
                <p className="teamname"></p>

                <div className="image-row">
                    <img src={game1} alt="Image 1" className="image-item" />
                    <img src={game2} alt="Image 2" className="image-item" />
                    <img src={game3} alt="Image 3" className="image-item" />
                </div>
            </div>
            <div id="section3-after" className="section-after" />

            {/* Technologies 4 */}
            <div id="sectionN4" className="section">
                <h2 className="section3-text-title1 section3-text-titles"> Technologies </h2>
            </div>
            <div id="section3-after" className="section-after" />

            {/* Service Types 5 */}
            <div id="sectionN5" className="section">
                <h2 className="section3-text-title1 section3-text-titles">Concept</h2>
                <h2 className="section3-text-title2 section3-text-titles">Art</h2>
                <h2 className="section3-text-title3 section3-text-titles">Music</h2>
                <h2 className="section3-text-title4 section3-text-titles">Backend</h2>
            </div>
            <div id="section3-after" className="section-after" />

            {/* Processes 6 */}
            <div id="sectionN6" className="section">
                <h2 className="section3-text-title1 section3-text-titles">Game development process</h2>
                <p className="">The team can convert your vision into some of the most popular Casino games in the world</p>

                <div className="imageDevProcess-row">
                    <img src={devProcess1} alt="devProcess1" className="image-item" />
                    <img src={devProcess2} alt="devProcess1" className="image-item" />
                </div>

            </div>
            <div id="section3-after" className="section-after" />

            {/* Team 7 */}
            <div id="sectionN7" className="section">
                <h2 className="section3-text-title1 section3-text-titles">Team</h2>
                <p>Our team of executives, artists, designers, and developers work on a wide range of Casino games for various platforms. The team can meet your unique needs and requirements, and bring your vision to life with cutting-edge visualizations and world-class game mechanics.</p>
                <div className="imageTeam-row">
                    <div className="person">
                        <img src={person1} alt="Predrag Popovic" className="imageTeam-item" />
                        <p className="teamname">Predrag Popovic</p>
                        <p className="teamrole">CEO</p>
                    </div>
                    <div className="person">
                        <img src={person2} alt="Milos Jovanovic" className="imageTeam-item" />
                        <p className="teamname">Milos Jovanovic</p>
                        <p className="teamrole">CTO</p>
                    </div>
                    <div className="person">
                        <img src={person3} alt="Grigorije Lazarevic" className="imageTeam-item" />
                        <p className="teamname">Grigorije Lazarevic</p>
                        <p className="teamrole">ART</p>
                    </div>
                    <div className="person">
                        <img src={person4} alt="Luka Drakic" className="imageTeam-item" />
                        <p className="teamname">Luka Drakic</p>
                        <p className="teamrole">SALES</p>
                    </div>
                </div>
            </div>
            <div id="section3-after" className="section-after" />

            <div id="section4" className="section">
                <div className="animated-lines">
                    <div className="animated-line animated-line-1"></div>
                    <div className="animated-line animated-line-2"></div>
                    <div className="animated-line animated-line-3"></div>
                    <div className="animated-line animated-line-4"></div>
                    <div className="animated-line animated-line-5"></div>
                    <div className="animated-line animated-line-6"></div>
                </div>

                <div className="section4-blocks">
                    <div className="section4-block">
                        <div className="section4-img">
                            <img alt="Dizajn" src={datsSrc4dizajn} />
                        </div>
                        <div className="section4-text">
                            <div className="section4-text-desc">
                                <div className="section4-text-title">
                                    <Trans i18nKey="card1Title" components={{ br: <br /> }} />
                                </div>
                                <div className="section4-text-description">
                                </div>
                            </div>
                            <button onClick={() => showCapDescElement(1)}>
                                <Trans i18nKey="cardBtn" components={{ br: <br /> }} /></button>
                        </div>

                    </div>
                    <div className="section4-block">
                        <div className="section4-img">
                            <img alt="Web" src={datsSrc7web} />
                        </div>
                        <div className="section4-text">
                            <div className="section4-text-desc">
                                <div className="section4-text-title">
                                    <Trans i18nKey="card2Title" components={{ br: <br /> }} />
                                </div>
                                <div className="section4-text-description">
                                </div>
                            </div>
                            <button onClick={() => showCapDescElement(2)}>
                                <Trans i18nKey="cardBtn" components={{ br: <br /> }} /></button>
                        </div>

                    </div>
                    <div className="section4-block">
                        <div className="section4-img">
                            <img alt="Mobilne Aplikacije" src={datsSrc10mobile} />
                        </div>
                        <div className="section4-text">
                            <div className="section4-text-desc">
                                <div className="section4-text-title">
                                    <Trans i18nKey="card3Title" components={{ br: <br /> }} />
                                </div>
                                <div className="section4-text-description">
                                </div>
                            </div>
                            <button onClick={() => showCapDescElement(3)}>
                                <Trans i18nKey="cardBtn" components={{ br: <br /> }} /></button>
                        </div>

                    </div>
                    <div className="section4-block">
                        <div className="section4-img">
                            <img alt="Analitika" src={datsSrc1analitika} />
                        </div>
                        <div className="section4-text">
                            <div className="section4-text-desc">
                                <div className="section4-text-title">
                                    <Trans i18nKey="card4Title" components={{ br: <br /> }} />
                                </div>
                                <div className="section4-text-description">
                                </div>
                            </div>
                            <button onClick={() => showCapDescElement(4)}>
                                <Trans i18nKey="cardBtn" components={{ br: <br /> }} /></button>
                        </div>

                    </div>
                    <div className="section4-block">
                        <div className="section4-img">
                            <img alt="Softveri" src={datsSrc8softver} />
                        </div>
                        <div className="section4-text">
                            <div className="section4-text-desc">
                                <div className="section4-text-title">
                                    <Trans i18nKey="card5Title" components={{ br: <br /> }} />
                                </div>
                                <div className="section4-text-description">
                                </div>
                            </div>
                            <button onClick={() => showCapDescElement(5)}>
                                <Trans i18nKey="cardBtn" components={{ br: <br /> }} /></button>
                        </div>

                    </div>
                    <div className="section4-block">
                        <div className="section4-img">
                            <img alt="Animacije" src={datsSrc2animacija} />
                        </div>
                        <div className="section4-text">
                            <div className="section4-text-desc">
                                <div className="section4-text-title">
                                    <Trans i18nKey="card6Title" components={{ br: <br /> }} />
                                </div>
                                <div className="section4-text-description">
                                </div>
                            </div>
                            <button onClick={() => showCapDescElement(6)}>
                                <Trans i18nKey="cardBtn" components={{ br: <br /> }} /></button>
                        </div>

                    </div>
                    <div className="section4-block">
                        <div className="section4-img">
                            <img alt="Igre" src={datsSrc3game} />
                        </div>
                        <div className="section4-text">
                            <div className="section4-text-desc">
                                <div className="section4-text-title">
                                    <Trans i18nKey="card7Title" components={{ br: <br /> }} />
                                </div>
                                <div className="section4-text-description">
                                </div>
                            </div>
                            <button onClick={() => showCapDescElement(7)}>
                                <Trans i18nKey="cardBtn" components={{ br: <br /> }} /></button>
                        </div>

                    </div>
                    <div className="section4-block">
                        <div className="section4-img">
                            <img alt="Drustvene Mreze" src={datsSrc5drustvenemreze} />
                        </div>
                        <div className="section4-text">
                            <div className="section4-text-desc">
                                <div className="section4-text-title">
                                    <Trans i18nKey="card8Title" components={{ br: <br /> }} />
                                </div>
                                <div className="section4-text-description">
                                </div>
                            </div>
                            <button onClick={() => showCapDescElement(8)}>
                                <Trans i18nKey="cardBtn" components={{ br: <br /> }} /></button>
                        </div>

                    </div>
                    <div className="section4-block">
                        <div className="section4-img">
                            <img alt="Sadrzaj" src={datsSrc6kreiranjesadrzaja} />
                        </div>
                        <div className="section4-text">
                            <div className="section4-text-desc">
                                <div className="section4-text-title">
                                    <Trans i18nKey="card9Title" components={{ br: <br /> }} />
                                </div>
                                <div className="section4-text-description">
                                </div>
                            </div>
                            <button onClick={() => showCapDescElement(9)}>
                                <Trans i18nKey="cardBtn" components={{ br: <br /> }} /></button>
                        </div>

                    </div>
                    <div className="section4-block">
                        <div className="section4-img">
                            <img alt="Prodavnice" src={datsSrc9onlineprodaja} />
                        </div>
                        <div className="section4-text">
                            <div className="section4-text-desc">
                                <div className="section4-text-title">
                                    <Trans i18nKey="card10Title" components={{ br: <br /> }} />
                                </div>
                                <div className="section4-text-description">
                                </div>
                            </div>
                            <button onClick={() => showCapDescElement(10)}>
                                <Trans i18nKey="cardBtn" components={{ br: <br /> }} /></button>
                        </div>

                    </div>
                </div>
            </div>
            {/* <div id="section4-after" className="section-after" /> */}


            {/* Contact 8 */}
            <div id="section5" className="section">

                <div className="section5-contact">
                    <p>
                        <Trans i18nKey="contactTitle" components={{ br: <br /> }} /></p>
                    <h2>
                        <Trans i18nKey="contactDesc" components={{ br: <br /> }} /></h2>
                    <button className="explore-button" onClick={() => {
                        const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}`;
                        window.location.href = mailtoLink;
                        ReactGa.event({ 'category': 'Kontakt', 'action': 'Klik', 'label': 'Kontakt' });
                    }}>
                        <Trans i18nKey="contactBtn" components={{ br: <br /> }} />
                    </button>
                    <div className="section5-phone-num-parent">
                        <div className="section5-phone-num">
                            <a href="tel:+38169660990">+381 (0)69 660 990</a>
                            <a href="tel:+381628201373">+381 (0)62 820 1373</a>
                        </div>
                        <div className="section5-phone-num">
                        </div>
                    </div>
                </div>
                <div className="section5-footer">
                    <p>{copyrightText}</p>
                </div>
                <div className="section5-footer-links">
                    <div className="social-block">
                        {/* <a href="#" target="_blank">
                            <img alt="twitter" src={twitterPng} />
                        </a>
                        <a href="#" target="_blank">
                            <img alt="facebook" src={facebookPng} />
                        </a> */}
                        <a href="https://instagram.com/virsag.digital?igshid=MzNlNGNkZWQ4Mg==" target="_blank">
                            <img alt="instagram" src={instagramPng} />
                        </a>
                        <a href="https://www.linkedin.com/company/virsag-digital/" target="_blank">
                            <img alt="linkedin" src={inPng} />
                        </a>
                    </div>
                </div>
            </div>

        </div>
    );


})